export const BG_PRIMARY = '#9AA7B8';
export const BG_PRIMARY_GRADIENT = '#8a96a5';
export const BG_SECONDARY = '#F4F1E9';
export const BG_ALTERNATIVE = '#053A28';
export const TEXT_PRIMARY = '#FFFFFF';
export const TEXT_SECONDARY = BG_PRIMARY;

// Icon & Navigation
export const BG_NAVIGATION = `#fff`;
export const BG_NAVIGATION_ICON_COLOR = `#000`;

// Frame
export const WEDDING_BORDER_COLOR = `#fff`;

// DEFAULT STYLES
export const BUTTON_COLOR_TEXT = '#000';
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
